<template>
    <base-content>
        <template #button>
            <a-button type="primary" @click="showEdit(0)">新增</a-button>
        </template>
        <a-breadcrumb style="margin-bottom:10px">
            <a-breadcrumb-item></a-breadcrumb-item>
            <a-breadcrumb-item v-for="item in breadcrumb" :key="item.id" @click="parent_id = item.id">
                <a>{{ item.title }}</a>
            </a-breadcrumb-item>
            <a-breadcrumb-item></a-breadcrumb-item>
        </a-breadcrumb>
        <a-table :dataSource="table_data" :columns="columns" :pagination="false" bordered rowKey="id">
            <template #operation="scoped">
                <a-button-group>
                    <a-button type="link" size="small" @click="parent_id = scoped.record.id">进入</a-button>
                    <a-button type="link" size="small" @click="showEdit(scoped.record.id)">编辑</a-button>
                    <a-button type="link" size="small" @click="$refs.DataLogModal.show(scoped.record.id)">管理</a-button>
                    <a-button type="link" size="small">删除</a-button>
                </a-button-group>
            </template>
        </a-table>
        <DataLogModal ref="DataLogModal" data_table="storage" />
        <a-modal v-model:visible="edit_modal_visible" :title="edit_modal_title" @ok="saveEdit">
            <a-form ref="edit_form" :model="edit_form" :label-col="{ span: 4, offset: 1 }" :wrapper-col="{ span: 15 }">
                <a-form-item name="name" label="储存区名称">
                    <a-input v-model:value="edit_form.name"></a-input>
                </a-form-item>
                <a-form-item name="description" label="描述">
                    <a-input v-model:value="edit_form.description"></a-input>
                </a-form-item>
                <a-form-item name="parent_id" label="上级储存区">
                    <a-tree-select v-model:value="edit_form.parent_id" :treeData="storage_tree" :replaceFields="{ children: 'children', title: 'name', key: 'id', value: 'id' }" treeDefaultExpandAll />
                </a-form-item>
            </a-form>
        </a-modal>
    </base-content>
</template>

<script>
import DataLogModal from '../components/modal/DataLog.vue';
export default {
    components: {
        DataLogModal,
    },
    data() {
        return {
            breadcrumb: [],
            parent_id: 0,
            table_data: [],
            columns: [
                {
                    title: '名称',
                    dataIndex: 'name',
                    align: 'center',
                },
                {
                    title: '描述',
                    dataIndex: 'description',
                },
                {
                    title: '操作',
                    slots: { customRender: 'operation' },
                    width: 180,
                    align: 'center',
                },
            ],
            edit_modal_visible: false,
            edit_modal_title: '添加储存区',
            edit_storage_id: 0,
            edit_form: {},
            edit_rule: {
                name: [{ required: true, message: '请输入储存区名称', trigger: 'blur' }],
                description: [{ required: true, message: '请输入储存区描述', trigger: 'blur' }],
            },
            storage_tree: [],
        };
    },
    watch: {
        parent_id(value) {
            let insert_flag = true;
            let new_breadcrumb = [];
            for (let i = 0; i < this.breadcrumb.length; i++) {
                if (this.breadcrumb[i].id == value) {
                    insert_flag = false;
                    new_breadcrumb.push(this.breadcrumb[i]);
                } else if (insert_flag) {
                    new_breadcrumb.push(this.breadcrumb[i]);
                }
            }
            if (insert_flag) {
                for (let i = 0; i < this.table_data.length; i++) {
                    if (this.table_data[i].id == value) {
                        new_breadcrumb.push({ id: value, title: this.table_data[i].name });
                    }
                }
            }
            this.breadcrumb = new_breadcrumb;
            this.getData();
        },
    },
    mounted() {
        this.getData();
        this.$api('getStorageList', { parent_id: -1 }).then((res) => {
            res.storage_list.forEach((item) => {
                if (item.id == 0) {
                    this.breadcrumb = [{ id: 0, title: item.name }];
                }
            });
        });
    },
    methods: {
        getData(ban_cache = false) {
            this.$api('getStorageList', { parent_id: this.parent_id }, ban_cache).then((res) => {
                this.table_data = res.storage_list;
            });
        },
        showEdit(storage_id) {
            this.edit_modal_visible = true;
            this.edit_storage_id = storage_id;
            this.$api('getStorageTree', {}, true).then((res) => {
                this.storage_tree = res.storage_tree;
            });
            if (storage_id == 0) {
                this.edit_modal_title = '新增储存区';
                this.edit_form = { name: '', description: '', parent_id: this.parent_id };
            } else {
                for (let i = 0; i < this.table_data.length; i++) {
                    if (this.table_data[i].id == storage_id) {
                        this.edit_modal_title = `编辑储存区 【${this.table_data[i].name}】`;
                        this.edit_form = { name: this.table_data[i].name, description: this.table_data[i].description, parent_id: this.table_data[i].parent_id };
                    }
                }
            }
        },
        saveEdit() {
            let data = {
                name: this.edit_form.name,
                description: this.edit_form.description,
                parent_id: this.edit_form.parent_id,
            };
            if (this.edit_storage_id == 0) {
                this.$api('addStorage', data).then((res) => {
                    if (res.result == true) {
                        this.$message.success('新增成功');
                        this.getData(true);
                    } else {
                        this.$message.error('新增失败');
                    }
                    this.edit_modal_visible = false;
                });
            } else {
                data.storage_id = this.edit_storage_id;
                this.$api('editStorage', data).then((res) => {
                    if (res.result == true) {
                        this.$message.success('修改成功');
                        this.getData(true);
                    } else {
                        this.$message.error('修改失败');
                    }
                    this.edit_modal_visible = false;
                });
            }
        },
    },
};
</script>

<style></style>
